<template>
  <div class="qr" v-if="value">
    <div class="qr__image">
      <vue-qr :text="value" :size="76" :margin="8"></vue-qr>
    </div>

    <div class="qr__text caprino">
      {{ $t('misc.qr_text') }}
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  name: "QrCode",

  components: {
    VueQr
  },

  props: {
    value: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style lang="scss">
.qr {
  display: flex;
  align-items: center;
  height: 76px;

  &__image {
    height: 100%;
    flex-shrink: 0;

    overflow: hidden;
    border-radius: 8px;
  }

  &__text {
    color: var(--rir-metropolis);
    opacity: 0.8;

    margin-left: 8px;
  }
}
</style>
