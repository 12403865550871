<template>
  <div class="more-items" v-if="itemsSet.length > 0">
    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 12, narrow: 12 }">
        <h2 class="more-items__title ricotta harakiri--text">
          {{ title }}
        </h2>
      </r-col>
    </r-row>

    <r-row :wrap="false" v-if="itemsSet.length > 0" class="px-4">
      <r-col v-for="item in itemsSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <r-announcement :image="item.images.length > 0 ? item.images[0] : ''"
                        :title="item.title"
                        size="rhaenise"
                        :sub-title="item.category.title"
                        @click.native="goToItem(item)"
        >
          <template v-slot:imageContent v-if="item.label">
            <r-badge :title="item.label" inversion color="amelie" />
          </template>
        </r-announcement>
      </r-col>
    </r-row>
  </div>
</template>

<script>
export default {
  name: "MoreItemsList",

  props: {
    title: {
      type: String,
      default: () => '',
    },
    dataSet: {
      type: [Array, Object],
      default: () => [],
    },
    type: {
      type: String,
      default: () => '',
    },
    category: {
      type: [Number, String],
      default: () => '',
    }
  },

  computed: {
    itemsSet() {
      return this.dataSet.filter(item => {
        if (item.category && item.id != this.$route.params.id) return item?.category?.id === this.category;
      });
    },
  },

  methods: {
    goToItem(item) {
      this.$router.push({path: `/${this.type}/${item.id}`});
    },
  }
}
</script>

<style lang="scss">
.more-items {

  &__title {
    margin-bottom: 16px;
  }
}
</style>
