<template>
  <div class="additional-info">
    <div v-for="item of data" :key="item.id" class="additional-info__item">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 12, narrow: 2 }">
          <span class="roquefort harakiri--text">{{ item.key }}</span>
        </r-col>

        <r-col :cols="{ widest: 4, wide: 4, middle: 12, narrow: 4 }">
          <span class="mozzarella harakiri--text">{{ item.value }}</span>
        </r-col>
      </r-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdditionalInfo",

  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
  }
}
</script>

<style lang="scss">
.additional-info {

  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
</style>
